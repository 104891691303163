import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MenuItem} from './menu-item';
import {KeycloakService} from "smf-common";

@Injectable()
export class MenuService {

  public menuItems: BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>([]);

  public initialItems: Array<MenuItem> = [];

  public constructor(private authService: KeycloakService) {
    // this.menuItems = [];
  }

  public initMenu(items: Array<MenuItem>) {
    this.initialItems = this.flatten(items);
    this.authService.userSubject.subscribe(user => this.updateMenu(this.initialItems));
  }

  private flatten(items: Array<MenuItem>): Array<MenuItem> {
    let flattened = [];
    for (const item of items) {
      flattened.push(item);
      if (item.submenu) {
        item.submenu.forEach(sub => sub.parent = item);
        const submenu = item.submenu;
        item.submenu = [];
        flattened = flattened.concat(this.flatten(submenu));
      }
    }
    return flattened;
  }

  public updateMenu(items: Array<MenuItem>) {
    const filtered = [];
    items.forEach(item => item.submenu && (item.submenu = []));

    items.filter(item =>
      item.permission ? item.permission.checkPermission(this.authService) : true
    ).forEach(item =>
      item.parent ? item.parent.submenu.push(item) : filtered.push(item)
    );
    this.menuItems.next(filtered);
  }

  public getMenu(): Observable<Array<MenuItem>> {
    return this.menuItems;
  }

}


