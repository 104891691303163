import {Inject, Injectable} from '@angular/core';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;

    public constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {

        // User Settings
        // -----------------------------------
        this.user = {
            name: 'Empty',
            job: 'Empty',
            picture: 'assets/img/dummy.png'
        };

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'SMF',
            description: '',
            year: ((new Date()).getFullYear())
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    public getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    public getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    public getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    public setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    public setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    public setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    public toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    /**
     * Download all required bootstrap files
     *  - bootstrap ltr
     *  - bootstrap rtl
     * OnLoad on each file check if its need to be disabled
     */
    public preloadBootstrap() {
        /// Head to append css
        const head = document.head || document.getElementsByTagName('head')[0] as HTMLElement;

        /// Bootstrap direction files
        const urls = {
          'rtl' : '//cdn.rtlcss.com/bootstrap/v4.0.0/css/bootstrap.min.css',
          'ltr' : '//stackpath.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
        };

        /// RTL languages
        const rtlLanguages = [
          'he', 'ar'
        ];

        /// RTL flag
        const isRTL = rtlLanguages.indexOf( this.i18NextService.language ) !== -1;
        this.setLayoutSetting('isRTL', isRTL);

        /// Append csses
        for ( const type in urls ) {
          const link = document.createElement('link') as HTMLLinkElement;
          link.rel = 'stylesheet';
          link.href = urls[type];
          link.id = 'bootstrap-' + type;

          link.onload = () => {
            const direction = isRTL ? 'rtl' : 'ltr';
            link.disabled = direction === type ? false : true;
          };
          head.insertBefore(link, head.children[0]);
        }
      }

    /**
     * Toggle between ltr and rtl
     * @param isRTL
     */
    public setLayoutDirection( isRTL: boolean ) {

      this.layout.isRTL = isRTL;

      const ltr = document.querySelector('#bootstrap-ltr') as HTMLLinkElement;
      const rtl = document.querySelector('#bootstrap-rtl') as HTMLLinkElement;

      if ( isRTL ) {
        rtl.disabled = false;
        ltr.disabled = true;
      } else {
        rtl.disabled = true;
        ltr.disabled = false;
      }
    }

}
