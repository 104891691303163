import {Component, OnInit} from '@angular/core';
import {AffiliateMeta, IAffiliateMeta} from '../../../core/api/model/affiliate-meta';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  public user: IAffiliateMeta = AffiliateMeta.getInstance();

  /// UI
  public accountLabel: string;
  // public accountSlots: number;

  public constructor() {
  }

  public ngOnInit() {
    // this.accountSlots = (this.user.slots || 0);
    if (this.user.upgrades && this.user.upgrades.pro && this.user.upgrades.pro.active) {
      this.accountLabel = 'smf_affiliate_badge_pro';
      const slots = this.user.upgrades.pro['slots'] || 0
      if (slots === 0) {
        this.accountLabel = 'smf_affiliate_badge_free'
      } else {
        if (this.user.upgrades.pro['type'] === 'PREMIUM') {
          this.accountLabel = 'smf_affiliate_badge_premium'
        }
      }
    } else {
      if (this.user.upgrades && this.user.upgrades.enterprise && this.user.upgrades.enterprise.active) {
        this.accountLabel = 'smf_affiliate_badge_enterprise';
      } else {
        this.accountLabel = 'smf_affiliate_badge_free'
      }
    }
  }
}
