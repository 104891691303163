import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Created} from './model/extensions';
import {IResultList, IResultPage, PageQuery, ResultPage} from './result-page';
import {Serializable} from './serializable';

@Injectable()
export class SmfRestService {
  public host: string = environment.apiHost;
  public path: string = this.host;

  public constructor(private http: HttpClient) {
  }

  public get<T>(path: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${this.path}/${path}`, {params: params});
  }

  public getPlain(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${this.path}/${path}`, {responseType: 'text'});
  }

  public getList<T>(path: string, params?: HttpParams): Observable<T[]> {
    return this.http.get<IResultList<T>>(`${this.path}/${path}`, {params: params}).pipe(map(r => r.data));
  }

  public getRawPage<T>(path: string): Observable<IResultPage<T>> {
    return this.http.get<IResultPage<T>>(`${this.path}/${path}`);
  }

  public post<T>(path: string, object: Serializable | any) {
    // let body = ('serialize' in object) ? (object as Serializable).serialize() : object;
    const body = (<Serializable>object).serialize ? (<Serializable>object).serialize() : object;
    return this.http.post<T>(`${this.path}/${path}`, body);
  }

  public add<ID>(path: string, object: Serializable | any): Observable<Created<ID>> {
    // let body = ('serialize' in object) ? (object as Serializable).serialize() : object;
    const body = (<Serializable>object).serialize ? (<Serializable>object).serialize() : object;
    return this.http.post<Created<ID>>(`${this.path}/${path}`, body);
  }

  public create(path: string, object: any): Observable<URL> {
    return this.http.post(`${this.path}/${path}`, object, {observe: 'response'}).pipe(map(r => new URL(r.headers.get('Location'))));
  }

  public put<T>(path: string, object: any) {
    return this.http.put(`${this.path}/${path}`, object);
  }

  public update<T>(path: string, object: any) {
    const body = (<Serializable>object).serialize ? (<Serializable>object).serialize() : object;
    return this.http.put<T>(`${this.path}/${path}`, body);
  }

  public delete(path: string): Observable<HttpResponse<Object>> {
    return this.http.delete(`${this.path}/${path}`, {observe: 'response'});
  }

  public remove<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${this.path}/${path}`);
  }

  public patch<T>(path: string, object: any) {
    return this.http.patch(`${this.path}/${path}`, object);
  }

  public getPage<T>(path: string, query?: PageQuery<T>): Observable<ResultPage<T>> {
    let params = new HttpParams();
    if (query) {
      query.keys().forEach(key => {
        for (const param of query.getAll(key)) {
          params = params.append(key, param);
        }
        // params = params.set(key, query.get(key))
      });
    }
    return this.http.get<IResultPage<T>>(`${this.path}/${path}`, {params}).pipe(map(page => ResultPage.from<T>(page)));
  }

}
