export interface IResultPage<E> {
  data: Array<E>;
  size: number;
  found: number;
  offset: number;
  limit: number;
  // additionalProperties: Map<string, any>;
}

export interface IResultList<E> {
  data: Array<E>;
}

export class ResultPage<E> implements IResultPage<E> {

  public static from<T>(other: IResultPage<T>): ResultPage<T> {
    return new ResultPage(other.data, other.size, other.found, other.offset, other.limit);
  }

  public static mapFrom<T, E>(other: IResultPage<E>, transform: (e: E) => T): ResultPage<T> {
    return new ResultPage<T>(other.data.map(transform), other.data.length, other.found, other.offset, other.limit);
  }

  public constructor(public data: E[], public size: number, public found: number, public offset: number, public  limit: number) {
  }

  public totalPages() {
    return Math.ceil(this.found / this.limit);
  }
}

interface QueryUpdate {
  param: string;
  value?: string;
  op: 'a' | 'd' | 's';
}

export class PageQuery<R> {
  private static LIMIT = 'limit';
  private static OFFSET = 'offset';
  private static DEFAULT_LIMIT = 10;
  private static DEFAULT_OFFSET = 0;

  private cloneFrom: PageQuery<R> | null = null;
  private updates: QueryUpdate[] | null = null;
  private map: Map<string, string[]>;

  // private limit: number;
  // private offset: number;
  public static getDefault<T>(limit: number = PageQuery.DEFAULT_LIMIT, offset: number = PageQuery.DEFAULT_OFFSET): PageQuery<T> {
    return new PageQuery<T>().setLimit(limit).setOffset(offset);
  }

  public constructor(params: { param: string, value: string[] }[] = []) {
    this.map = new Map<string, string[]>();
    this.map.set(PageQuery.LIMIT, ['0']);
    this.map.set(PageQuery.OFFSET, ['0']);
    params.forEach(e => this.map.set(e.param, e.value));
  }

  public nextPage(): PageQuery<R> {
    const offset = this.getOffset() + this.getLimit();
    return this.setOffset(offset);
  }

  public previousPage(): PageQuery<R> {
    const offset = this.getOffset() - this.getLimit();
    return this.setOffset(offset < 0 ? 0 : offset);
  }

  public setPage(page: number): PageQuery<R> {
    const offset = this.getLimit() * (page - 1);
    return this.setOffset(offset);
  }

  public getLimit(): number {
    this.init();
    return Number.parseInt(this.get(PageQuery.LIMIT));
  }

  public setLimit(limit: number) {
    return this.set(PageQuery.LIMIT, limit.toString());
  }

  public getOffset(): number {
    return Number.parseInt(this.get(PageQuery.OFFSET));
  }

  public setOffset(offset: number) {
    return this.set(PageQuery.OFFSET, offset.toString());
  }

  public has(param: string): boolean {
    this.init();
    return this.map !.has(param);
  }

  public get(param: string): string | null {
    this.init();
    const res = this.map.get(param);
    return !!res ? res[0] : null;
  }

  public getAll(param: string): string[] | null {
    this.init();
    return this.map.get(param) || null;
  }

  public keys(): string[] {
    this.init();
    return Array.from(this.map.keys());
  }

  public append(param: string, value: string): PageQuery<R> {
    return this.clone({param, value, op: 'a'});
  }

  public set(param: string, value: string): PageQuery<R> {
    if (value === null || value === undefined) {
      return this.clone({param, op: 'd'});
    }
    return this.clone({param, value, op: 's'});
  }

  public delete(param: string, value?: string): PageQuery<R> {
    return this.clone({param, value, op: 'd'});
  }


  private clone(update: QueryUpdate): PageQuery<R> {
    const clone = new PageQuery<R>();
    clone.cloneFrom = this.cloneFrom || this;
    clone.updates = (this.updates || []).concat([update]);
    return clone;
  }

  private init() {
    if (this.map === null) {
      this.map = new Map<string, string[]>();
    }
    if (this.cloneFrom !== null) {
      this.cloneFrom.init();
      this.cloneFrom.keys().forEach(key => this.map.set(key, this.cloneFrom.map.get(key)));
      this.updates.forEach(update => {
        switch (update.op) {
          case 'a':
          case 's':
            const base = (update.op === 'a' ? this.map.get(update.param) : undefined) || [];
            base.push(update.value);
            this.map.set(update.param, base);
            break;
          case 'd':
            if (update.value !== undefined) {
              const base = this.map.get(update.param) || [];
              const idx = base.indexOf(update.value);
              if (idx !== -1) {
                base.splice(idx, 1);
              }
              if (base.length > 0) {
                this.map.set(update.param, base);
              } else {
                this.map.delete(update.param);
              }
            } else {
              this.map.delete(update.param);
              break;
            }
        }
      });
      this.cloneFrom = null;
    }
  }
}
