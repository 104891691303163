import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AffiliateMeta} from 'app/core/api/model/affiliate-meta';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  public static STORAGE_KEY = 'hide_welcome';
  public visible = true;
  public isChecked = false;

  public constructor(private router: Router) { }

  public ngOnInit() {
    const requiredActions = AffiliateMeta.getInstance().requiredActions
    if (requiredActions && requiredActions.includes('updateProfile')) {
      // User is required to fill contact form - dont show welcome modal
      this.visible = false;
    } else if (this.router.url.includes('/codegenerator')) {
      this.visible = false;
    } else {
      this.visible = !window.localStorage.getItem(WelcomeComponent.STORAGE_KEY);
    }
  }

  public goto(path: String) {
    this.visible = false;
    this.router.navigateByUrl(`/${path}`);
  }

  public persistVisibility() {
    if (this.isChecked) {
      window.localStorage.setItem(WelcomeComponent.STORAGE_KEY, '1');
    } else {
      window.localStorage.removeItem(WelcomeComponent.STORAGE_KEY);
    }
  }
}
