import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  FirebaseStorageService,
  MoneyPipe,
  UserFinancialProfile,
  UserFinancialProfileStatus,
  UserFinancialProfileType,
  WithdrawConfig,
  WithdrawRequestData
} from 'smf-common';
import {WithdrawService} from 'app/shared/components/financial/withdraw/withdraw.service';
import {Utilities} from 'app/shared/utilities';
import {DatePipe} from '@angular/common';

export interface WithdrawResponse {
  data?: any,
  error?: Error
}

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {

  // AMOUNT TO BE CALC & PAID
  @Input() public maxGrossAmount: number;
  // FINANCIAL PROFILE
  @Input() public profile: UserFinancialProfile
  // EMIT REQUEST TO CLOSE THE FORM
  @Output() public cancel = new EventEmitter<boolean>()
  // EMIT REQUEST TO SHOW PROFILE EDIT PAGE/MODAL
  @Output() public profileEdit = new EventEmitter<boolean>()
  // EMIT WHEN REQUEST IS SAVED * EMIT EITHER ERROR OR UPDATED PROFILE
  @Output() public save = new EventEmitter<WithdrawResponse>()

  // UI STEP
  public step: 'loading' | 'expired' | 'no-profile' | 'warning' | 'invalid-profile' | 'pending-profile' | 'payment' = 'loading'

  // ACCOUNT TYPE FROM FINANCIAL PROFILE
  public businessType: 'PERSONAL' | 'EXEMPT' | 'AUTHORISED'

  // PERSONAL ACCOUNTS CAN BE PAID BY CHECK OR BANK
  public personalType: 'PAYBOX' | 'CHECK' | 'BANK' = 'BANK'

  // CONFIG FOR ALL AVAILABLE PAYMENT METHODS FOR PERSONAL ACCOUNT
  public withdrawMethodAvailability = {
    bank: true,
    check: true,
    paybox: true
  }

  // USER DEFINED GROSS AMOUNT
  public grossAmount: number

  // KEYBOARD EVENT DEBOUNCE HANDLER
  public kbDebounce: any;

  // Withdraw Invoice Config
  public withdrawConfig: WithdrawConfig

  // CONFIG FOR PAYMENT FORMS
  public formConfig = {
    personal: {
      BANK: [
        {
          name: 'bank',
          label: 'smf_bank_number',
          value: '',
          validator: Validators.required,
          error: 'smf_field_is_required'
        },
        {
          name: 'branch',
          label: 'smf_bank_branch',
          value: '',
          validator: Validators.required,
          error: 'smf_field_is_required'
        },
        {
          name: 'account',
          label: 'smf_account_number',
          value: '',
          validator: Validators.required,
          error: 'smf_field_is_required'
        },
        {
          name: 'holder',
          label: 'smf_account_name',
          value: '',
          validator: Validators.required,
          error: 'smf_field_is_required'
        }
      ],
      // https://smarty.atlassian.net/browse/BOOM-221
      // CHECK: [
      //   {
      //     name: 'name',
      //     label: 'smf_account_name',
      //     value: '',
      //     validator: Validators.required,
      //     error: 'smf_field_is_required'
      //   },
      // ],
      PAYBOX: [
        {
          name: 'name',
          label: 'smf_account_name',
          value: '',
          validator: Validators.required,
          error: 'smf_field_is_required'
        },
        {
          name: 'phone',
          label: 'smf_phone_number',
          value: '',
          validator: [Validators.pattern(/^(972|0)5[0123458]\d{7}$/), Validators.required],
          error: 'smf_phone_error'
        },
      ]
    },
    exempt: [
      {
        name: 'invoice',
        label: 'smf_invoice',
        value: '',
        validator: Validators.required,
        error: 'smf_field_is_required'
      },
    ],
    authorised: [
      {
        name: 'invoice',
        label: 'smf_invoice',
        value: '',
        validator: Validators.required,
        error: 'smf_field_is_required'
      },
    ]
  }
  public withdraw: WithdrawRequestData

  // FORM MODEL *GENERATED FROM FORM CONFIG
  public paymentForm = new FormGroup({})

  // UI FLAG
  public inAjaxRequest: boolean
  public isSubmitted = false

  // FILE REF FOR UPLOAD
  public fileRef: File

  // PATH TO USER FINANCIAL BUCKET
  private readonly userPath: string

  public constructor(private uploader: FirebaseStorageService, private service: WithdrawService, private moneyPipe: MoneyPipe, private datePipe: DatePipe) {
    this.userPath = this.uploader.generateUserPath('/fin')
  }

  public async ngOnInit() {
    if (!this.profile) {
      // No profile, need to create one
      this.step = 'no-profile'
      return
    }

    if (this.profile.dateExpire && this.profile.dateExpire * 1000 < Date.now()) {
      // Document is expired
      this.step = 'expired'
      return
    }

    if (this.profile.status === UserFinancialProfileStatus.PENDING) {
      this.step = 'pending-profile'
      return
    }

    if (this.profile.status === UserFinancialProfileStatus.INVALID) {
      this.step = 'invalid-profile'
      return
    }

    // https://smarty.atlassian.net/browse/BOOM-201
    // if (this.profile.type !== UserFinancialProfileType.PERSONAL && this.profile.dateExpire * 1000 < Date.now() + 60 * 60 * 24 * 30 * 1000) {
    //   // Document is about to expire
    //   this.step = 'warning'
    //   return
    // }
    if (this.profile.type !== UserFinancialProfileType.PERSONAL) {
      // Get withdraw config if user type is business
      this.withdrawConfig = await this.service.getConfig().toPromise().catch(_ => ({
        _id: '',
        invoices: {
          month: new Date().getMonth(),
          year: new Date().getFullYear()
        }
      }))
    }
    this.configurePayment()
  }

  // Configure UI for withdraw details procedure
  private configurePayment() {
    // Prefill gross amount
    this.grossAmount = this.maxGrossAmount

    // Load payment form
    this.step = 'payment'

    // Store business type
    this.businessType = this.profile.type as any

    // Calc vat and taxes
    this.calcTaxes()

    // Check if there is disabled payment methods for personal accounts
    if (this.profile.type === UserFinancialProfileType.PERSONAL && this.profile.disabledWithdrawMethods && this.profile.disabledWithdrawMethods.length > 0) {
      this.profile.disabledWithdrawMethods.forEach(method => this.withdrawMethodAvailability[method] = false)
    }

    // Generate form model
    this.generateFormModel()
  }

  public isAmountValid() {
    const money = new RegExp(/^\d{3,}\.?\d{0,2}$/)
    if (!money.test(this.grossAmount.toString())) {
      return false
    }
    if (this.grossAmount < 300) {
      return false
    }
    return this.grossAmount <= this.maxGrossAmount;
  }

  public updateTaxes() {
    if (this.kbDebounce) {
      window.clearTimeout(this.kbDebounce)
    }
    this.kbDebounce = window.setTimeout(this.calcTaxes.bind(this), 100)
  }

  private calcTaxes() {
    this.withdraw = this.service.calcTaxes(this.grossAmount, this.profile.vat || 0, this.profile.tax || 0)
  }

  // STORE TEMP FILE REF *USED IN SAVE METHOD LATER
  public makeFileRef(event: Event) {
    this.fileRef = (event.target as HTMLInputElement).files[0]
  }

  // UPLOAD FILE TO FIREBASE
  private upload(file: File): Promise<string> {
    const dateFormat = () => {
      const now = new Date();
      const date = [
        now.getDate(), now.getMonth() + 1, now.getFullYear(),
        now.getHours(), now.getMinutes(), now.getSeconds()
      ].map(i => i.toString().padStart(2, '0'))
      const hour = date.splice(3)
      return date.join('-') + '_' + hour.join(':')
    }
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop()
      const fileName = `invoice${dateFormat()}.${fileExtension}`
      this.uploader.uploadFile(this.userPath, file, fileName).then(data => {
        console.log(data);
        resolve(data.metadata.name)
      }, error => {
        reject(error)
      })
    })
  }

  public onFormSubmit() {
    if (this.businessType === 'PERSONAL') {
      this.onPersonalSubmit()
    } else {
      this.onBusinessSubmit()
    }
  }

  public onPersonalSubmit() {
    if (this.inAjaxRequest) {
      return
    }
    this.inAjaxRequest = true
    const data: WithdrawRequestData = {...this.withdraw}
    data.paymentType = this.personalType
    data[this.personalType.toLowerCase()] = this.paymentForm.getRawValue()

    if (data.paybox && data.paybox.phone) {
      // ADD HE COUNTRY CODE
      data.paybox.phone = data.paybox.phone.replace(/^05/, '9725');
    }

    this.service.request(data).subscribe(_ => {
      this.save.emit({data: true, error: undefined})
      this.inAjaxRequest = false
    }, error => {
      this.inAjaxRequest = false
      this.save.emit({data: undefined, error: error})
    })
  }

  public async onBusinessSubmit() {
    if (this.inAjaxRequest) {
      return
    }
    this.inAjaxRequest = true
    const file: string | undefined = await this.upload(this.fileRef).catch(error => {
      console.error(error);
      return undefined
    })
    if (!file) {
      this.save.emit({data: undefined, error: new Error('Firebase goes away')})
      this.inAjaxRequest = false
      return
    }
    const data: WithdrawRequestData = {...this.withdraw}
    // data.paymentType = 'BANK'
    data.invoice = {
      file: file
    }

    this.service.request(data).subscribe(_ => {
      this.save.emit({data: true, error: undefined})
      this.inAjaxRequest = false
    }, error => {
      this.uploader.deleteFile(this.userPath, data.invoice.file)
      this.inAjaxRequest = false
      this.save.emit({data: undefined, error: error})
    })
  }

  // SEND CANCEL REQUEST
  public onCancel() {
    this.cancel.emit(true)
  }

  // EMIT REQUEST TO LOAD FINANCIAL PROFILE FORM
  public opUpdateFinancialProfile() {
    this.profileEdit.emit(true)
  }

  // LOAD PAYMENT FORM
  public onProceedToPayment() {
    this.configurePayment();
  }

  // FILL THE paymentForm BASED ON FINANCIAL PROFILE VARIABLES
  public generateFormModel() {
    this.paymentForm = new FormGroup({})
    const addInput = (field) => this.paymentForm.addControl(field.name, new FormControl(field.value, field.validator))
    if (this.businessType === 'PERSONAL') {
      this.formConfig.personal[this.personalType].forEach(addInput)
    } else {
      this.formConfig[this.businessType.toLowerCase()].forEach(addInput)
    }
  }

  public currency() {
    return Utilities.currency()
  }

  public setSubmitted() {
    this.isSubmitted = true
  }

  public moneyFormat(n: number) {
    return this.moneyPipe.transform(n)
  }

  public transformDate() {
    const date = new Date()
    // Month from service is range 1-12
    date.setMonth(this.withdrawConfig.invoices.month - 1)
    date.setFullYear(this.withdrawConfig.invoices.year)
    return this.datePipe.transform(date, 'MMM yyyy', null, 'en-EN');
  }

}
