import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {KeycloakService, User} from "smf-common";

@Injectable()
export class UserblockService {
  public userBlockVisible: boolean = true;

  constructor(private auth: KeycloakService) {
    this.auth.userSubject.subscribe(user => {
      if (user && !this.userBlockVisible) {
        this.setVisibility(true);
      }
    });
  }

  getUser(): Subject<User> {
    return this.auth.userSubject;
  }

  login() {
    this.auth.loginWithPopup();
  }

  logout() {
    this.auth.logout();
  }

  getVisibility() {
    return this.userBlockVisible;
  }

  setVisibility(stat = true) {
    this.userBlockVisible = stat;
  }

  toggleVisibility() {
    this.userBlockVisible = !this.userBlockVisible;
  }

}
