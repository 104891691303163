import { Component, OnInit, ViewChild } from '@angular/core';
// const screenfull = require('screenfull');
// const browser = require('jquery.browser');
// declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';

import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MenuItem} from '../../core/menu/menu-item';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public navCollapsed = true; // for horizontal layout
    public menuItems: Observable<Array<MenuItem>>; // for horizontal layout

    public isNavSearchVisible: boolean;
    @ViewChild('fsbutton', {static: false}) public fsbutton;  // the fullscreen button

    public constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().pipe(take(4)); // for horizontal layout

    }

    public ngOnInit() {
        this.isNavSearchVisible = false;
        // if (browser.msie) { // Not supported under IE
        //     this.fsbutton.nativeElement.style.display = 'none';
        // }
    }

    public toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    public isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
}
