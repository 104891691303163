import {KeycloakService, Permission, RolePermission} from 'smf-common'
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs'

enum FinderAffiliateRoles {
  agent_trainee = 'agent_trainee',
  agent_professional = 'agent_professional',
  agent_enterprise = 'agent_enterprise',
  agent_applicant = 'applicant',
  agent_manager = 'agent_manager',
  agent_free = 'agent_free',
  agent_leader = 'agent_leader'
}

/**
 * Items only for applicant
 */
export class UpgradesRolePermission extends RolePermission {
  public static ONLY_APPLICANT = new UpgradesRolePermission(
    [FinderAffiliateRoles.agent_applicant.valueOf()],
    false,
    [
      FinderAffiliateRoles.agent_trainee.valueOf(),
      FinderAffiliateRoles.agent_professional.valueOf(),
      FinderAffiliateRoles.agent_enterprise.valueOf()
    ]
  );
}

/**
 * @see smf-common/RolePermission
 * Same as RolePermission
 */
export class AffiliateRolePermission extends RolePermission {
  public static AFFILIATE_TRAINEE = new AffiliateRolePermission([FinderAffiliateRoles.agent_trainee.valueOf()]);
  public static AFFILIATE_PROFESSIONAL = new AffiliateRolePermission([FinderAffiliateRoles.agent_professional.valueOf()]);
  public static AFFILIATE_ENTERPRISE = new AffiliateRolePermission([FinderAffiliateRoles.agent_enterprise.valueOf()]);
  public static AFFILIATE_MANAGER = new AffiliateRolePermission([FinderAffiliateRoles.agent_manager.valueOf()]);
  public static AFFILIATE_LEADER = new AffiliateRolePermission([FinderAffiliateRoles.agent_leader.valueOf()]);

  public static AFFILIATE_MIN_REQUIRE_ENTERPRISE = new AffiliateRolePermission([
    FinderAffiliateRoles.agent_enterprise.valueOf()
  ]);
  public static AFFILIATE_MIN_REQUIRE_PROFESSIONAL = new AffiliateRolePermission([
    FinderAffiliateRoles.agent_enterprise.valueOf(),
    FinderAffiliateRoles.agent_professional.valueOf()
  ]);
  public static AFFILIATE_MIN_REQUIRE_TRAINEE = new AffiliateRolePermission([
    FinderAffiliateRoles.agent_enterprise.valueOf(),
    FinderAffiliateRoles.agent_professional.valueOf(),
    FinderAffiliateRoles.agent_trainee.valueOf()
  ]);

  public static AFFILIATE_ONLY_FREE = new AffiliateRolePermission( [FinderAffiliateRoles.agent_free], false, [
    FinderAffiliateRoles.agent_trainee, FinderAffiliateRoles.agent_professional, FinderAffiliateRoles.agent_enterprise
  ]);

}

export class UserIdPermission implements Permission {
  private userIds: Set<string>

  public constructor(userIds: string[]) {
    this.userIds = new Set(userIds)
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, keycloak: KeycloakService): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkPermission(keycloak)
  }

  public checkPermission(keycloak: KeycloakService): boolean {
    if (!keycloak.isAuthenticated()) {
      return false
    }
    console.log('subject: ', keycloak.keycloak().subject)
    return this.userIds.has(keycloak.keycloak().subject)
  }
}
