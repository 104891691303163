import {MenuItem} from '../core/menu/menu-item'
import {AffiliateRolePermission, UpgradesRolePermission} from '../core/api/model/permission'
import {environment} from '../../environments/environment'
import {RolePermission} from 'smf-common'

const Agents: MenuItem = {
  text: 'Projects',
  translate: 'smf_menu_projects',
  link: '/agents',
  icon: 'fas fa-clipboard',
  permission: AffiliateRolePermission.AFFILIATE_MIN_REQUIRE_PROFESSIONAL
}

const FreeUsers: MenuItem = {
  text: 'Projects',
  translate: 'smf_menu_projects',
  link: '/freeusers',
  icon: 'fas fa-clipboard',
  permission: AffiliateRolePermission.AFFILIATE_ONLY_FREE
}

const SuperAffiliates: MenuItem = {
  text: 'Super affiliate',
  translate: 'smf_menu_super_affiliate',
  link: '/super-affiliates',
  icon: 'fa fa-users',
  submenu: [
    {
      text: 'Dashboard',
      translate: 'smf_menu_dashboard',
      link: '/super-affiliates/dashboard'
    }, {
      text: 'Projects',
      translate: 'smf_menu_projects',
      link: '/super-affiliates/projects'
    },
  ],
  permission: AffiliateRolePermission.AFFILIATE_MANAGER
}

const Upgrades: MenuItem = {
  text: 'Upgrades',
  translate: 'smf_menu_upgrades',
  link: '/upgrades',
  icon: 'fa fa-cloud-upload-alt',
  permission: UpgradesRolePermission.ONLY_APPLICANT
}

const Videos: MenuItem = {
  text: 'Video',
  translate: 'smf_menu_video',
  link: '/videos/welcome',
  icon: 'fas fa-video',
  permission: AffiliateRolePermission.AFFILIATE_MIN_REQUIRE_TRAINEE
}

const Guide: MenuItem = {
  text: 'Guide',
  translate: 'smf_menu_guide',
  link: '/guide',
  icon: 'fas fa-compass'
}

const CodeGenerator: MenuItem = {
  text: 'Code Generator',
  translate: 'smf_menu_code_generator',
  link: '/codegenerator',
  icon: 'fas fa-user-lock',
  regions: ['IL']
}

const MyBonuses: MenuItem = {
  text: 'My Bonuses',
  translate: 'smf_menu_my_bonuses',
  link: '/bonuses',
  icon: 'fas fa-network-wired',
  permission: AffiliateRolePermission.AFFILIATE_LEADER
}

const Earnings: MenuItem = {
  text: 'Earnings',
  translate: 'smf_earnings_report',
  link: '/earnings',
  icon: 'fas fa-receipt'
}

const TdfMarket: MenuItem = {
  text: 'MarketPlace',
  translate: 'smf_menu_market',
  link: '/finderella/market',
  icon: 'fas fa-shopping-bag',
  submenu: [
    {
      text: 'Market',
      translate: 'smf_menu_market_sub',
      link: '/finderella/market/orders',
    },
    {
      text: 'My Slots',
      translate: 'smf_menu_market_slots',
      link: '/finderella/market/mybusinesses',
    },
  ],
  permission: new RolePermission(['affiliate'], undefined, undefined, 'finder-tdbm')
}

const FinderellaLeads: MenuItem = {
  text: 'Leads',
  translate: 'smf_menu_leads',
  link: '/finderella/leads',
  icon: 'fas fa-binoculars'
}

const AffiliateReferrals: MenuItem = {
  text: 'Referrals',
  translate: 'smf_menu_referrals',
  link: '/referrals',
  icon: 'fas fa-users',
  permission: new RolePermission(['agent_professional', 'agent_enterprise'])
}

// permission: new UserIdPermission([
//   'eea17a1d-803c-495b-b7cd-080aab2cbbd7', // Gil
//   '1d9cfbb0-02b6-42cb-acb7-a1c4271c3c49', // Vlad
//   '531806e6-b478-4fb2-95ce-24d90db7a140' // Simo
// ])

const headingMain: MenuItem = {
  text: 'Main Navigation',
  heading: true
}

// const Profile: MenuItem = {
//   text: 'Profile',
//   translate: 'Profile',
//   icon: 'fas fa-user-circle',
//   submenu: [
//     {
//       text: 'Financial',
//       translate: 'Financial',
//       link: '/profile/financial',
//     },
//   ],
//   permission: new RolePermission(['affiliate'], undefined, undefined, 'finder-tdbm')
// }

export const menu: Array<MenuItem> = [
  /// headingMain,
  FreeUsers,
  Agents,
  SuperAffiliates,
  Earnings,
  /// Videos,
  Guide,
  CodeGenerator,
  MyBonuses,
  Upgrades,
  TdfMarket,
  FinderellaLeads,
  AffiliateReferrals
].filter(item => item.regions ? item.regions.includes(environment.deployment.region.name) : true)
