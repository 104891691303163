import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { MenuService } from './menu/menu.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {SmfRestService} from './api/smf-rest.service';

@NgModule({
    imports: [
    ],
    providers: [
        SettingsService,
        /// TranslatorService,
        MenuService,
        SmfRestService
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {
    public constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
