import {LayoutComponent} from '../layout/layout.component';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: '/agents', pathMatch: 'full'},
      {path: 'agents', loadChildren: './agents/agents.module#AgentsModule'},
      {path: 'freeusers', loadChildren: './freeusers/freeusers.module#FreeusersModule'},
      {path: 'super-affiliates', loadChildren: './super-affiliate/super-affiliate.module#SuperAffiliateModule'},
      {path: 'upgrades', loadChildren: './upgrades/upgrades.module#UpgradesModule'},
      {path: 'videos', loadChildren: './videos/videos.module#VideosModule'},
      {path: 'guide', loadChildren: './guide/guide.module#GuideModule'},
      {path: 'codegenerator', loadChildren: './codegenerator/codegenerator.module#CodegeneratorModule'},
      {path: 'bonuses', loadChildren: './bonuses/bonuses.module#BonusesModule'},
      {path: 'earnings', loadChildren: './earnings/earnings.module#EarningsModule'},
      {path: 'referrals', loadChildren: './referrals/referrals.module#ReferralsModule'},
      {path: 'finderella/market', loadChildren: './finderella/tdf-market/tdf-market.module#TdfMarketModule'},
      {path: 'finderella/leads', loadChildren: './finderella/leads/leads.module#LeadsModule'},
      {path: 'profile', loadChildren: './profile/profile.module#ProfileModule'}
    ]
  },
  // Not found
  {path: '**', redirectTo: ''}
];
