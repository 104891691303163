import {Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LngDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import {environment} from 'environments/environment';
import {authInitializer, AuthModule, FirebaseStorageService, KeycloakService} from 'smf-common';
import {AffiliateMeta, IAffiliateMeta} from './core/api/model/affiliate-meta';
import {registerLocaleData} from '@angular/common';
import localeHe from '@angular/common/locales/he';

registerLocaleData(localeHe);

const i18nextOptions = {
  whitelist: ['en', 'bg', 'ro', 'nl', 'he', 'es'],
  fallbackLng: 'en',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    prefix: '{',
    suffix: '}'
  },
  backend: {
    allowMultiLoading: false,
    crossDomain: true,
    loadPath: function () {
      return environment.translationsUrl;
    },
    parse: function (data: string) {
      return Object.values(JSON.parse(data))[0]
    },
    customHeaders: {
      'Content-Type': 'application/json',
      'X-API-Key': 'tgpak_gezv63ttn5yta2btouzgi2zymrzxg5tbmjtg24lonjyxeoa'
    }
  },
  detection: {
    order: ['localStorage', 'environment', 'navigator'],
    lookupLocalStorage: 'lang',
    caches: ['localStorage']
  }
};

export function appInit(i18next: ITranslationService) {
  const languageFromEnvironment = {
    name: 'environment',
    lookup() {
      return environment.deployment.region.lang;
    }
  };

  const lngDetector = new LngDetector();
  lngDetector.addDetector(languageFromEnvironment);

  return () => i18next
    .use(XHR)
    .use(LanguageDetector)
    .use(lngDetector)
    .init(i18nextOptions);

}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export function preAuthInitializer(http: HttpClient, keycloak: KeycloakService) {
  keycloak.onAuthSuccess = () => new Promise((resolve, reject) => {
    const url = environment.apiHost + '/api/agent/profiles/me';
    http.get<IAffiliateMeta>(url).subscribe((res) => {
      AffiliateMeta.getInstance().init(res);
      resolve();
    }, error => reject(error));
  });
  return authInitializer(keycloak, environment);
}

export function firebaseInit(firebase: FirebaseStorageService) {
  return () => {
    return firebase.init({
      bucket: 'gs://smf-affiliates',
      apiKey: 'AIzaSyCuZgWzoNqTWMpaQDEojPW8zSS2WI9WVk8',
      tokenApi: environment.apiHost + '/api/firebase/token'
    })
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    I18NextModule.forRoot({})
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: firebaseInit,
      deps: [FirebaseStorageService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preAuthInitializer,
      multi: true,
      deps: [HttpClient, KeycloakService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [I18NEXT_SERVICE],
      multi: true
    },
    Title,
    // see https://next.angular.io/guide/i18n#i18n-pipes
    {
      provide: LOCALE_ID,
      deps: [I18NEXT_SERVICE],
      useFactory: localeIdFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
