import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MenuItem} from '../../core/menu/menu-item';
import {MenuService} from '../../core/menu/menu.service';
import {SettingsService} from '../../core/settings/settings.service';
import {UserblockService} from './userblock/userblock.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public menuItems: Observable<Array<MenuItem>>;
  public router: Router;
  public $doc: any = null;
  public $docEvnFunc = (e) => {
    if (!this.parents(e.target, '.aside-container').length) {
      this.settings.setLayoutSetting('asideToggled', false);
    }
  };

  public constructor(public menu: MenuService, public settings: SettingsService, public injector: Injector, public userblockService: UserblockService) {

    this.menuItems = menu.getMenu();

  }

  public ngOnInit() {

    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.setLayoutSetting('asideToggled', false);
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();

  }

  public anyClickClose() {
    this.$doc = document.addEventListener('click', this.$docEvnFunc);
  }

  public ngOnDestroy() {
    if (this.$doc) {
      document.removeEventListener('click', this.$docEvnFunc);
    }
  }

  toggleSubmenuClick(event) {

    event.preventDefault();

    if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

      let ul = event.currentTarget.nextElementSibling;

      // hide other submenus
      let parentNav = ul ? this.parents(ul, '.sidebar-subnav') : [null];
      document.querySelectorAll('.sidebar-subnav').forEach(el => {
        // if element is not a parent or self ul
        if (el !== parentNav[0] && el !== ul) {
          this.closeMenu(el);
        }
      });

      // abort if not UL to process
      if (!ul) {
        return;
      }

      ul.style.height = ul.scrollHeight + 'px';
      this.addClass(ul, 'opening');

    }

  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.style.height = '0px';
    this.removeClass(elem, 'opening');
  }

  public toggleSubmenuHover(event) {

    /// ???

    if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
      event.preventDefault();
      console.log(11234);
      this.removeFloatingNav();

      let ul = event.currentTarget.nextElementSibling;
      let anchor = event.currentTarget;

      if (!ul.length) {
        return; // if not submenu return
      }

      const $aside: HTMLElement = document.querySelector('.aside-container');
      const $asideInner: HTMLElement = document.querySelector('.aside-container .aside-inner'); // for top offset calculation
      const $sidebar: HTMLElement = document.querySelector('.aside-container .sidebar');
      const mar = parseInt($asideInner.style.paddingTop, 0) + parseInt($aside.style.paddingTop, 0);
      const itemTop = (parseInt(anchor.parentNode.style.top) + mar) - $sidebar.scrollTop;

      const floatingNav = ul.clone().appendTo($aside);
      const vwHeight = document.body.clientHeight;

      // let itemTop = anchor.position().top || anchor.offset().top;

      this.removeClass(floatingNav, 'opening');
      this.addClass(floatingNav, 'nav-floating');

      floatingNav.style.position = this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute';
      floatingNav.style.top = itemTop;
      floatingNav.style.bottom = (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto';

      floatingNav.addEventListener('mouseleave', () => {
        floatingNav.remove()
      });

      floatingNav.querySelectorAll('a').forEach(item => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          const routeTo = e.target.setAttribute('route');///  $(this).attr('route');
          if (routeTo) {
            this.router.navigate([routeTo]);
          }
        });
      });

      this.listenForExternalClicks();

    }

  }

  private parents(el, parentSelector) {

    const parents = [];
    let p = el.parentNode;

    while (p) {

      if (p === document) {
        break;
      }

      if ((p as HTMLElement).matches(parentSelector)) {
        parents.push(p);
      }
      p = p.parentNode;
    }

    return parents;
  }

  private removeClass(node, className) {
    const fullClass = node.getAttribute('class').replace(className, '').trim();
    node.setAttribute('class', fullClass);
  }

  private addClass(node, className) {
    this.removeClass(node, className);
    const fullClass = node.getAttribute('class');
    node.setAttribute('class', `${fullClass} ${className}`);
  }

  public listenForExternalClicks() {
    const externalClicks = (e) => {
      if (!this.parents(e.target, '.aside-container').length) {
        this.removeFloatingNav();
        document.removeEventListener('click', externalClicks);
      }
    };
    document.addEventListener('click', externalClicks);
  }

  public removeFloatingNav() {
    document.querySelectorAll('.nav-floating').forEach( item => {
      item.remove()
    });
  }

  public isSidebarCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }

  public isSidebarCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  public isEnabledHover() {
    return this.settings.getLayoutSetting('asideHover');
  }

  public logout() {
    this.userblockService.logout();
  }
}
