import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators, FinancialProfileService} from 'app/shared/components/financial/financial-profile-form/financial-profile.service';
import {AffiliateMeta, IAffiliateContact} from 'app/core/api/model/affiliate-meta';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Output() public save = new EventEmitter<IAffiliateContact>()
  @Output() public error = new EventEmitter<Error>()
  @Output() public cancel = new EventEmitter<Boolean>()

  // UI FLAGS
  public isSubmitted = false
  public inAjaxRequest = false

  // CONTACT FORM
  public form = new FormGroup({
    name: new FormControl(AffiliateMeta.getInstance().contact.name, Validators.required),
    phone: new FormControl(AffiliateMeta.getInstance().contact.phone, [Validators.pattern(AppValidators.phone), Validators.required])
  })

  public constructor(private service: FinancialProfileService) {
  }

  public changeSubmitFlag() {
    this.isSubmitted = true
  }

  public ngOnInit() {
  }

  public onSubmit() {
    if (this.inAjaxRequest) {
      return
    }
    this.inAjaxRequest = true
    const contact: IAffiliateContact = this.form.getRawValue()
    this.service.updateAffiliateProfile(contact.name, contact.phone).subscribe(_ => {
      this.inAjaxRequest = false
      this.save.emit(contact)
    }, error => {
      this.inAjaxRequest = false
      this.error.emit(error)
    })
  }

  public onCancel() {
    this.cancel.emit(true)
  }
}
