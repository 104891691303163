export interface IAffiliateMeta {
  name: string;
  dateCrated: number;
  userId: string;
  smartyId: number;
  upgrades: IAffiliateUpgrades;
  slots?: number;
  email: string;
  phone: string;
  contact: IAffiliateContact;
  requiredActions?: string[];
}

export interface IUpgradePackage {
  name: string;
  active: boolean;
  dateActivated: number;
  dateApproved: number;
}

export interface IAffiliateUpgrades {
  free: IUpgradePackage;
  trainee: IUpgradePackage;
  pro: IUpgradePackage;
  enterprise: IUpgradePackage;
  manager: IUpgradePackage;
}

export interface IAffiliateContact {
  name: string,
  phone: string,
  email: string
}

export class AffiliateMeta implements IAffiliateMeta {
  private static instance: AffiliateMeta;

  public dateCrated: number;
  public name: string;
  public userId: string;
  public smartyId: number;
  public upgrades: IAffiliateUpgrades;
  public slots?: number;
  public email: string;
  public phone: string;
  public contact: IAffiliateContact;
  public requiredActions?: string[];

  private constructor() {
  }

  public static getInstance() {
    if (!AffiliateMeta.instance) {
      AffiliateMeta.instance = new AffiliateMeta();
    }
    return AffiliateMeta.instance;
  }

  public init(object: IAffiliateMeta) {
    this.name = object.name;
    this.dateCrated = object.dateCrated;
    this.smartyId = object.smartyId;
    this.upgrades = object.upgrades;
    this.slots = object.slots;
    this.contact = object.contact;
    this.requiredActions = object.requiredActions
  }
}
