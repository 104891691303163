import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {WithdrawConfig, WithdrawRequestData} from 'smf-common';

@Injectable({
  providedIn: 'root'
})
export class WithdrawService {
  private path = environment.apiHost + '/api/agent/payments/withdraws';

  public constructor(private http: HttpClient) {
  }

  public request(data: WithdrawRequestData) {
    return this.http.post(this.path, data)
  }

  public getConfig() {
    const url = environment.apiHost + '/api/configs/withdraws_config'
    return this.http.get<WithdrawConfig>(url)
  }


  private round = (n: number): number => Math.round((n + Number.EPSILON) * 100) / 100
  private netValue = (amount: number, vat: number) => this.round((amount * 100) / (vat + 100))

  private applyVat = (amount: number, vat: number) => {
    const netAmount = this.netValue(amount, vat)
    return [netAmount, this.round(amount - netAmount)]
  }

  private applyTax = (amount: number, tax: number) => {
    const taxAmount = this.round((amount * tax) / 100)
    return [this.round(amount - taxAmount), taxAmount]
  }

  public calcTaxes(grossAmount: number, vat: number, tax: number) {
    const [preVatAmount, vatAmount] = this.applyVat(grossAmount, vat)
    const [netAmount, taxAmount] = this.applyTax(preVatAmount, tax)

    return {
      vat: vat,
      tax: tax,
      grossAmount: grossAmount,
      netAmount: netAmount,
      taxAmount: taxAmount,
      vatAmount: vatAmount
    }
  }
}
