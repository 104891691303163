import {Inject, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';

import {menu} from './menu';
import {routes} from './routes';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})

export class RoutesModule {
  public constructor(public menuService: MenuService, @Inject(I18NEXT_SERVICE) public tr: ITranslationService) {
    menuService.initMenu(menu);
  }
}
