import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseStorageService, UserFinancialProfile, UserFinancialProfileStatus, UserFinancialProfileTypeTax} from 'smf-common';
import {AppValidators, FinancialProfileService} from 'app/shared/components/financial/financial-profile-form/financial-profile.service';
import {AffiliateMeta} from 'app/core/api/model/affiliate-meta';
import {rejects} from 'assert';

export interface FinancialFormResponse {
  data?: Partial<UserFinancialProfile>,
  error?: Error
}


interface UploadedFilesItem {
  url: string
  name: string
}

@Component({
  selector: 'app-financial-profile-form',
  templateUrl: './financial-profile-form.component.html',
  styleUrls: ['./financial-profile-form.component.scss']
})
export class FinancialProfileFormComponent implements OnInit {

  @Input() public profile: UserFinancialProfile

  // CALLED WHEN FORM IS SUBMITTED - WILL RETURN SUBMITTED DATA OR ERROR FROM SERVER
  @Output() public save = new EventEmitter<FinancialFormResponse>()

  // CALLED WHEN FORM IS CANCELED
  @Output() public cancel = new EventEmitter<boolean>()

  // UI FLAG
  public inAjaxRequest = false
  // UI FLAG IS USED ONLY TO SHOW ERRORS(IF ANY) AFTER SUBMIT
  public isSubmitted = false

  // FINANCIAL PROFILE VIEW MODEL
  public financialForm = new FormGroup({})

  // SHOW INFO TEXT WHEN SELECTION BUSINESS TYPE
  public businessTypeInfoText = {
    PERSONAL: 'smf_fin_bis_type_hint_personal',
    EXEMPT: 'smf_fin_bis_type_hint_exempt',
    AUTHORISED: 'smf_fin_bis_type_hint_authorised'
  }

  // LAST UPLOADED FILES
  public uploadedFiles: UploadedFilesItem[] = []

  private fileMap: { [key: string]: File } = {}

  // PATH TO USER FINANCIAL BUCKET
  private readonly userPath: string

  private out(k1: string, k2?: string, fallback = '') {
    if (!this.profile) {
      return fallback
    }
    if (k1 && !k2) {
      return this.profile[k1]
    }
    if (!this.profile[k1]) {
      return fallback
    }
    return this.profile[k1][k2]
  }

  private parseDate(value: number | Date | undefined) {
    if (!value) {
      return ''
    }
    if (typeof value === 'number') {
      return new Date(value * 1000)
    } else {
      return value
    }
  }

  public constructor(private uploader: FirebaseStorageService, private service: FinancialProfileService) {
    this.userPath = this.uploader.generateUserPath('/fin')
  }

  public ngOnInit() {
    this.financialForm = new FormGroup({
      affiliate: new FormGroup({
        fullName: new FormControl(AffiliateMeta.getInstance().contact.name, Validators.required),
        phone: new FormControl(AffiliateMeta.getInstance().contact.phone, [Validators.pattern(AppValidators.phone), Validators.required]),
      }),
      type: new FormControl(this.out('type'), Validators.required),
      personalInfo: new FormGroup({
        name: new FormControl(this.out('personalInfo', 'name'), Validators.required),
        personalId: new FormControl(this.out('personalInfo', 'personalId'), [Validators.pattern(AppValidators.personalId), Validators.required])
      }),

      businessInfo: new FormGroup({
        name: new FormControl(this.out('businessInfo', 'name'), Validators.required),
        businessNumber: new FormControl(this.out('businessInfo', 'businessNumber'), [Validators.pattern(AppValidators.personalId), Validators.required])
      }),

      vat: new FormControl({
        value: this.out('vat'),
        disabled: true
      }, [Validators.pattern(AppValidators.taxes), Validators.required]),
      tax: new FormControl(this.out('tax'), [Validators.pattern(AppValidators.taxes), Validators.required]),
      dateExpire: new FormControl(this.profile && this.profile.dateExpire ? this.parseDate(this.profile.dateExpire) : '', [Validators.required, (control: FormControl) => {
        const valueDate = new Date(control.value)
        const today = this.getCurrentDate()
        if (valueDate.getTime() < today.getTime()) {
          return {invalidDate: true}
        }
        return null
      }]),
      attachments: new FormGroup({
        taxInfo: new FormControl('', Validators.required)
      }),

      personalExtended: new FormControl(this.profile && this.profile.type === 'PERSONAL' && !!this.profile.dateExpire)
    })
    this.onBusinessTypeChange()
    if (this.profile && this.profile.attachments) {
      Object.values(this.profile.attachments).forEach(async item => {
        this.uploadedFiles.push({
          url: await this.getFileULR(item.file),
          name: item.file
        })
      })
    }
  }

  // MAKE REF FROM FORM BUILDER TO FILE
  public makeFileRef(type: string, event: Event) {
    this.fileMap[type] = (event.target as HTMLInputElement).files[0]
  }

  // UPLOAD FILE TO FIREBASE
  private upload(file: File, name: string) {
    const dateFormat = () => {
      const now = new Date();
      const date = [
        now.getDate(), now.getMonth() + 1, now.getFullYear(),
        now.getHours(), now.getMinutes(), now.getSeconds()
      ].map(i => i.toString().padStart(2, '0'))
      const hour = date.splice(3)
      return date.join('-') + '_' + hour.join(':')
    }
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop()
      const fileName = `${name}${dateFormat()}.${fileExtension}`
      this.uploader.uploadFile(this.userPath, file, fileName).then(data => {
        console.log(data);
        resolve(data.metadata.name)
      }, error => {
        reject(error)
      })
    })
  }

  // REMOVE FILE FROM FIREBASE
  private remove(fileName: string) {
    this.uploader.deleteFile(this.userPath, fileName).then(success => {
      console.log(success);
    }, error => {
      console.error(error)
      alert('Remove failed')
    })
  }

  // UPDATE FORM FIELDS
  public onBusinessTypeChange() {
    const type = this.financialForm.value.type
    if (type === '') {
      return;
    }
    if (type === 'PERSONAL') {
      this.financialForm.get('vat').setValue(UserFinancialProfileTypeTax.PERSONAL.vat)
      this.financialForm.get('businessInfo').disable()
      this.financialForm.get('personalInfo').enable()
      this.onPersonalExtendedChange()
    } else {
      this.financialForm.get('vat').setValue(UserFinancialProfileTypeTax[type].vat)
      this.financialForm.get('tax').enable()
      this.financialForm.get('businessInfo').enable()
      this.financialForm.get('personalInfo').disable()
      this.financialForm.get('attachments').enable()
      this.financialForm.get('dateExpire').enable()
    }
  }

  public onPersonalExtendedChange() {
    if (this.financialForm.value.personalExtended) {
      this.financialForm.get('tax').enable()
      this.financialForm.get('attachments').enable()
      this.financialForm.get('dateExpire').enable()
    } else {
      this.financialForm.get('tax').setValue(UserFinancialProfileTypeTax.PERSONAL.tax)
      this.financialForm.get('tax').disable()
      this.financialForm.get('attachments').disable()
      this.financialForm.get('dateExpire').disable()
    }
  }

  // MAKE REQUEST TO SERVER
  public async onFinancialFormSubmit() {
    if (this.inAjaxRequest) {
      return false
    }
    this.inAjaxRequest = true
    const {affiliate, personalExtended, ...rawData} = this.financialForm.getRawValue()

    // UPDATE AFFILIATE NAME AND PHONE
    const saveSuccess = await this.updateAffiliateData(affiliate).catch(error => {
      this.inAjaxRequest = false
      this.save.emit({
        data: null,
        error: error
      })
      return false;
    })
    console.log(`saveSuccess: ${saveSuccess}`)
    if (!saveSuccess) {
      return;
    }

    this.updateFinancialProfile(rawData, personalExtended).then(profile => {
      this.inAjaxRequest = false
      this.save.emit({
        data: profile,
        error: null
      })
    }).catch(error => {
      this.inAjaxRequest = false
      this.save.emit({
        data: null,
        error: error
      })
    })
  }

  public updateFinancialProfile(data: Partial<UserFinancialProfile>, isPersonalExtended: boolean) {
    return new Promise(async (resolve, reject) => {
      const parseDate = (value: number | Date) => {
        if (typeof value === 'number') {
          return value / 1000
        } else {
          return value.getTime() / 1000
        }
      }
      const update: Partial<UserFinancialProfile> = {
        type: data.type,
        status: UserFinancialProfileStatus.PENDING,
        vat: UserFinancialProfileTypeTax[data.type].vat,
        tax: data.type !== 'PERSONAL' || isPersonalExtended ? parseInt(data.tax.toString(), 10) : UserFinancialProfileTypeTax[data.type].tax
      }
      if (data.type === 'PERSONAL') {
        update.personalInfo = data.personalInfo
        update.businessInfo = null
        update.dateExpire = null
        update.attachments = null
      } else {
        update.personalInfo = null
        update.businessInfo = data.businessInfo
      }
      if (data.type !== 'PERSONAL' || isPersonalExtended) {
        update.attachments = {}
        update.dateExpire = parseDate(data.dateExpire)
        await Promise.all(Object.entries(this.fileMap).map(async ([name, file]) => {
          update.attachments[name] = {
            file: await this.upload(file, name).catch(error => {
              console.log(error);
              reject('Firebase upload failed')
            })
          }
        }))
      }
      this.service.updateFinancialProfile(update).subscribe(_ => {
        this.fileMap = {}
        resolve(update)
      }, async error => {
        // Remove files from firebase
        await Promise.all(Object.keys(this.fileMap).map(async fileName => {
          await this.remove(data.attachments[fileName])
        }))
        reject(error)
      })
    })
  }

  public async updateAffiliateData(data: any) {
    return new Promise(async (resolve, reject) => {
      const compare = AffiliateMeta.getInstance().contact
      data.phone = data.phone.replace(/^05/, '9725')
      if (compare.name !== data.fullName || compare.phone !== data.phone) {
        await this.service.updateAffiliateProfile(data.fullName, data.phone).toPromise().catch(error => reject(error))
        AffiliateMeta.getInstance().contact.name = data.fullName
        AffiliateMeta.getInstance().contact.phone = data.phone
        resolve(true)
      } else {
        resolve(true)
      }
    }).catch(error => rejects(error))
  }

  // EMIT CANCEL EVENT
  public onCancel() {
    this.cancel.emit(true)
  }

  public async getFileULR(fileName: string) {
    return await this.uploader.getFileURL(this.userPath + '/' + fileName)
  }

  public changeSubmitFlag() {
    this.isSubmitted = true
  }

  public getCurrentDate() {
    const today = new Date()
    today.setUTCHours(0)
    today.setUTCMinutes(0)
    today.setUTCSeconds(0)
    today.setUTCMilliseconds(0)
    return today
  }

  public getDateExpireError() {
    const errors = this.financialForm.get('dateExpire').errors
    if (errors['required'] || !errors['invalidDate']) {
      return 'smf_field_is_required'
    }
    return 'smf_label_invalid_data'
  }
}
