import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserFinancialProfile} from 'smf-common';
import {Observable} from 'rxjs';
import {AffiliateMeta} from 'app/core/api/model/affiliate-meta';

export const AppValidators = {
  phone: /^(972|0)5[0123458]\d{7}$/,
  personalId: /^\d{9}$/,
  taxes: /^\d{1,2}$/
}

@Injectable({
  providedIn: 'root'
})
export class FinancialProfileService {
  private path = environment.apiHost + '/api/agent/financial-profile/requests';

  public constructor(private http: HttpClient) {
  }

  public updateFinancialProfile(data: Partial<UserFinancialProfile>) {
    return this.http.post(this.path, data)
  }

  public updateAffiliateProfile(name: string, phone: string) {
    const path = environment.apiHost + '/api/agent/profiles/me'
    return new Observable<any>(subscriber => {
      this.http.put(path, {name: name, phone: phone}).subscribe(r => {
        subscriber.next(r)
        AffiliateMeta.getInstance().contact.name = name
        AffiliateMeta.getInstance().contact.phone = phone
        const actions = AffiliateMeta.getInstance().requiredActions
        if (actions && actions.includes('updateProfile')) {
          actions.splice(actions.indexOf('actions'), 1)
        }
      }, error => subscriber.error(error))
      subscriber.complete()
    })
  }
}
