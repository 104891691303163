import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {AffiliateMeta, IAffiliateContact} from 'app/core/api/model/affiliate-meta';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public swalTarget = document.querySelector('app-root')

  @ViewChild('affContactModal', {static: true}) public affContactModal: SwalComponent

  public constructor(public toaster: ToasterService, @Inject(I18NEXT_SERVICE) public i18NextService: ITranslationService) {
  }

  public ngOnInit() {
    const requiredActions = AffiliateMeta.getInstance().requiredActions
    if (requiredActions && requiredActions.includes('updateProfile')) {
      // Check if user is required to add contact info
      this.affContactModal.fire()
    }
  }


  public closeAffContactModal() {
    this.affContactModal.dismiss()
  }

  public onAffContactError(error: Error) {
    console.error(error);
    this.toaster.pop('error',  this.i18NextService.t('smf_error'), this.i18NextService.t('smf_aff_contact_save_failed'))
  }

  public onAffContactSaved(_: IAffiliateContact) {
    this.toaster.pop('success', this.i18NextService.t('smf_success'), this.i18NextService.t('smf_aff_contact_save_success'))
    this.closeAffContactModal()
  }

}
